// This file was generatead by `node/plugin/plugins/generateImportGlobs.ts`.

export const pageFilesLazy = {};
export const pageFilesEager = {};
export const pageFilesExportNamesLazy = {};
export const pageFilesExportNamesEager = {};
export const neverLoaded = {};
export const isGeneratedFile = true;

const pageFilesLazyIsomorph1 = import.meta.glob('/**/*.page.(js|cjs|mjs|ts|cts|mts|jsx|cjsx|mjsx|tsx|ctsx|mtsx|vue|svelte|marko|md|mdx)', {"eager":false});
const pageFilesLazyIsomorph = {...pageFilesLazyIsomorph1};
pageFilesLazy['.page'] = pageFilesLazyIsomorph;

const pageFilesEagerRoute1 = import.meta.glob('/**/*.page.route.(js|cjs|mjs|ts|cts|mts|jsx|cjsx|mjsx|tsx|ctsx|mtsx|vue|svelte|marko|md|mdx)', {"eager":true});
const pageFilesEagerRoute = {...pageFilesEagerRoute1};
pageFilesEager['.page.route'] = pageFilesEagerRoute;

const pageFilesLazyClient1 = import.meta.glob('/**/*.page.client.(js|cjs|mjs|ts|cts|mts|jsx|cjsx|mjsx|tsx|ctsx|mtsx|vue|svelte|marko|md|mdx)', {"eager":false});
const pageFilesLazyClient = {...pageFilesLazyClient1};
pageFilesLazy['.page.client'] = pageFilesLazyClient;

const pageFilesExportNamesEagerClient1 = import.meta.glob('/**/*.page.client.(js|cjs|mjs|ts|cts|mts|jsx|cjsx|mjsx|tsx|ctsx|mtsx|vue|svelte|marko|md|mdx)', {"eager":true,"as":"extractExportNames"});
const pageFilesExportNamesEagerClient = {...pageFilesExportNamesEagerClient1};
pageFilesExportNamesEager['.page.client'] = pageFilesExportNamesEagerClient;

const pageFilesExportNamesEagerServer1 = import.meta.glob('/**/*.page.server.(js|cjs|mjs|ts|cts|mts|jsx|cjsx|mjsx|tsx|ctsx|mtsx|vue|svelte|marko|md|mdx)', {"eager":true,"as":"extractExportNames"});
const pageFilesExportNamesEagerServer = {...pageFilesExportNamesEagerServer1};
pageFilesExportNamesEager['.page.server'] = pageFilesExportNamesEagerServer;

const pageFilesExportNamesEagerIsomorph1 = import.meta.glob('/**/*.page.(js|cjs|mjs|ts|cts|mts|jsx|cjsx|mjsx|tsx|ctsx|mtsx|vue|svelte|marko|md|mdx)', {"eager":true,"as":"extractExportNames"});
const pageFilesExportNamesEagerIsomorph = {...pageFilesExportNamesEagerIsomorph1};
pageFilesExportNamesEager['.page'] = pageFilesExportNamesEagerIsomorph;
